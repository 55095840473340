import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="CyberSystems" />
        <meta property="og:description" content="Découvrez CyberSystems, votre expert en informatique et cybersécurité. Nous proposons des solutions avancées pour protéger vos données, optimiser vos systèmes et sécuriser votre entreprise contre les cybermenaces. Faites confiance à notre expertise pour une tranquillité d'esprit complète." />
        <meta property="og:image" content="https://mycybersystems.be/img/enterprise_logo.png" />
        <meta property="og:url" content="https://mycybersystems.be/" />
        <title>About | CyberSystems</title>
        <meta name="description" content="Découvrez CyberSystems, expert en informatique et en cybersécurité." />
        <meta name="keywords" content="cybersécurité, IT, informatique, dévellopement, Python, sécurité informatique, Namur, Gembloux, Sombreffe, Chaumont-Gistoux" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mycybersystems.be/" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <h1>À Propos de CyberSystems</h1>
      <p>
        Bienvenue chez CyberSystems, où l'innovation et l'expertise technique se rencontrent pour offrir des solutions informatiques exceptionnelles à Namur et dans le Brabant Wallon.<br />
        Nous sommes fiers de fournir des services qui répondent aux besoins uniques de chaque client, avec un engagement envers la qualité et l'excellence.<br />
        <br />
      </p>
      <div className="m-4">
        <figure>
          <video className="w-full max-w-3xl h-auto rounded-xl shadow-lg" controls >
            <source src="https://d3uryq9bhgb5qr.cloudfront.net/FreeTrial/Google_105057012075759117752/971643ff-e321-4a3c-b588-74e827f30b10/VIDEO/CyberSystems.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <figcaption>
              Découvrez CyberSystems !
          </figcaption>
        </figure>
        <div>
          <br />
          <h2>Notre Vision</h2>

          <p>Chez <strong>CyberSystems</strong>, nous avons une <strong>vision claire et ambitieuse</strong> : être le <strong>partenaire de choix</strong> pour toutes vos solutions technologiques.<br />
          Nous croyons fermement que la technologie doit servir de levier pour l'<strong>innovation</strong>, la <strong>croissance</strong> et l'<strong>efficacité</strong> dans chaque entreprise.<br />
          C’est pourquoi nous mettons tout en œuvre pour comprendre en profondeur vos <strong>défis</strong> et vos <strong>objectifs</strong>, afin de vous offrir des solutions qui ne sont pas seulement adaptées à vos besoins actuels,
          mais qui vous préparent également pour l'avenir.</p><br />

          <p>Nous nous engageons à créer des <strong>solutions robustes, sécurisées</strong> et à la <strong>pointe de la technologie</strong>, en anticipant les tendances et les évolutions du marché.<br />
          Notre objectif est de vous offrir une valeur ajoutée tangible, en transformant vos défis technologiques <strong>en opportunités de succès</strong>.<br />
          Nous croyons que chaque projet est une chance de repousser les limites de ce que la technologie peut accomplir et de contribuer à la <strong>réussite de nos clients.</strong></p>

          <p>Nous investissons constamment dans la <strong>formation continue</strong> de notre équipe et dans les dernières technologies pour rester à la pointe de l'innovation.<br />
          Nous adoptons une approche proactive pour identifier et résoudre les problèmes avant qu'ils ne surviennent, garantissant ainsi une performance optimale et une sécurité renforcée pour vos systèmes.</p>

          <p>Notre vision est également centrée sur la <strong>collaboration étroite</strong> avec nos clients.<br />
          Nous croyons que la <strong>communication ouverte</strong> et la <strong>compréhension mutuelle</strong> sont essentielles pour développer des solutions efficaces et durables.<br />
          En travaillant ensemble, nous pouvons atteindre des objectifs communs et créer des solutions qui sont non seulement techniquement performantes mais aussi parfaitement alignées avec votre <strong>stratégie d'affaires</strong>.</p>

          <p>En fin de compte, notre ambition est de bâtir des <strong>partenariats durables</strong> basés sur la <strong>confiance</strong> et la <strong>réussite partagée.</strong><br />
          Nous nous efforçons de <strong>dépasser vos attentes</strong> et de vous fournir des solutions qui vous permettent de vous concentrer sur ce que vous faites de mieux,<br />
          tout en nous chargeant de vos défis technologiques. Chez CyberSystems, nous ne nous contentons pas de suivre les tendances – nous les <strong>définissons.</strong></p>

          <p><strong>Contactez-nous</strong> pour découvrir comment notre vision peut <strong>transformer votre entreprise</strong> et vous aider à réaliser vos <strong>objectifs les plus ambitieux.</strong><br />
          Ensemble, ouvrons la voie à un <strong>avenir technologique brillant</strong> et <strong>prospère</strong>.</p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default About;