import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const App = () => {

  useEffect(() => {
  	const sidebarButton = document.querySelector(".router-component button");
    const sidebar = document.querySelector(".flex-container-app");
    const linkToClose = document.querySelectorAll('.link-to-close');

    function toggleSidebar() {
    	if (sidebar) {
    		sidebar.classList.toggle('show');
    	}
  	}

  	if (sidebarButton) {
	  	sidebarButton.addEventListener('click', toggleSidebar);
	  	linkToClose.forEach(link => {
	  		link.addEventListener('click', toggleSidebar);
	  	});
  	}

  	return () => {
  		if (sidebar) {
  			sidebarButton.removeEventListener('click', toggleSidebar);
  			linkToClose.forEach(link => {
  				link.removeEventListener('click', toggleSidebar)
  			});
  		}
  	};

  }, []);

  return (
  	<HelmetProvider>
	  	<Router>
	  		<Helmet>
	        <meta property="og:title" content="CyberSystems" />
        	<meta property="og:description" content="Découvrez CyberSystems, votre expert en informatique et cybersécurité. Nous proposons des solutions avancées pour protéger vos données, optimiser vos systèmes et sécuriser votre entreprise contre les cybermenaces. Faites confiance à notre expertise pour une tranquillité d'esprit complète." />
        	<meta property="og:image" content="https://mycybersystems.be/img/enterprise_logo.png" />
        	<meta property="og:url" content="https://mycybersystems.be/" />
        	<link rel="canonical" href="https://mycybersystems.be/" />
	  			<meta charset="UTF-8" />
	  			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	  			<meta name="keywords" content="cybersécurité, IT, informatique, dévellopement, Python, sécurité informatique, Namur, Gembloux, Sombreffe, Chaumont-Gistoux" />
	  		</Helmet>
	  		<div className="background-container"></div>
	  		<div className="background-gradient"></div>
	  		<div className="grid-container-app">
			  	<div className="flex-container-app show grow">
				  	<div className="flex-sidebar-app rounded-tr-xl">
				  		<Link className="" to="/"><h2>CyberSystems</h2></Link>
				  		<img className="" alt="Enterprise logo" src="/assets/img/enterprise_logo.png" />
			  		</div>
			  		<div className="flex-sidebar-app rounded-br-xl">
			  			<a href="tel:+32492443262" id="call-us">Appelez-Nous</a>
				 		<Link to="/" className="link-to-close p-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"> Home </Link>
				  		<Link to="/services" className="link-to-close p-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"> Services </Link>
				  		<Link to="/contact" className="link-to-close p-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"> Contact </Link>
				  		<Link to="/about" className="link-to-close p-2 bg-blue-500 text-white rounded shadow-md hover:bg-blue-600"> About </Link>
						</div>
					</div>
				<div className="router-component">
					<button id="sidebarButton">
						<img alt="Sidebar button" src="/assets/img/menu.svg" />
					</button>
		 			<Routes>
			  			<Route path="/" element={<Home />} />
					    <Route path="/services" element={<Services />} />
					    <Route path="/contact" element={<Contact />} />
					    <Route path="/about" element={<About />} />
				    </Routes>
				</div>
		  	</div>
	  	</Router>
  	</HelmetProvider>
  );
};

export default App;