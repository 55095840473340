import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephone: '',
    message: ''
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
    ...formData,
    [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('');

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Message envoyé avec succès !');
        setFormData({
          name: '',
          email: '',
          telephone: '',
          message: ''
        });
    } else {
        setStatus('Erreur lors de l\'envoi du message. Veuillez réessayer.');
      }
    } catch (error) {
      setStatus('Erreur lors de l\'envoi du message. Veuillez réessayer.');
    }
  };


  return (
    <div>
      <Helmet>
        <meta property="og:title" content="CyberSystems" />
        <meta property="og:description" content="Découvrez CyberSystems, votre expert en informatique et cybersécurité. Nous proposons des solutions avancées pour protéger vos données, optimiser vos systèmes et sécuriser votre entreprise contre les cybermenaces. Faites confiance à notre expertise pour une tranquillité d'esprit complète.." />
        <meta property="og:image" content="https://mycybersystems.be/img/enterprise_logo.png" />
        <meta property="og:url" content="https://mycybersystems.be/" />
        <link rel="canonical" href="https://mycybersystems.be/" />
        <title>Contact | CyberSystems</title>
        <meta name="description" content="Contactez CyberSystems, expert en informatique et en cybersécurité." />
        <meta name="keywords" content="cybersécurité, IT, informatique, dévellopement, Python, sécurité informatique, Namur, Gembloux, Sombreffe, Chaumont-Gistoux" />
        <meta name="robots" content="index, follow" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div>
        <h1>Nous Contacter:</h1>
        <p>Nous sommes ravis de répondre à toutes vos questions, commentaires ou propositions de collaboration.<br />
        Chez <strong>CyberSystems</strong>, nous nous engageons à offrir un service client exceptionnel.<br />
        Utilisez notre formulaire de contact ou nos coordonnées ci-dessous pour nous joindre rapidement.</p>
        <br />
      </div>
      <br />
      <div className="flex-container-contact flex flex-wrap gap-8">
        <form className="w-2/5" action="/api" method="post" onSubmit={handleSubmit}>
          <h2 className="m-2">Formulaire de Contact</h2>
          <p className="m-2">Remplissez le formulaire ci-dessous pour nous contacter rapidement.<br /> Nous nous engageons à répondre dans les plus brefs délais !</p>
          <div className="flex flex-col p-4 m-2">
            <div className="items-center">
              <div className="floating-label-group p-4 m-2">
                <input name="name" value={formData.name} onChange={handleChange} className="floating-input cursor-pointer shadow-md rounded-xl w-full transition-shadow duration-500 hover:shadow-xl focus:bg-sky-200" type="text" placeholder=" " required></input>
                <label className="floating-label">Nom</label>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="items-center">
                <div className="floating-label-group p-4 m-2">
                  <input name="email" value={formData.email} onChange={handleChange} className="floating-input cursor-pointer shadow-md rounded-xl w-full transition-shadow duration-500 hover:shadow-xl focus:bg-sky-200" type="text" placeholder=" " required></input>
                  <label className="floating-label">Email</label>
                </div>
              </div>
              <div className="items-center">
                <div className="floating-label-group p-4 m-2">
                  <input name="telephone" value={formData.telephone} onChange={handleChange} className="floating-input cursor-pointer shadow-md rounded-xl w-full transition-shadow duration-500 hover:shadow-xl focus:bg-sky-200" type="text" placeholder=" " required></input>
                  <label className="floating-label">Téléphone</label>
                </div>
              </div>
            </div>
            <div className="items-center">
              <div className="floating-label-group p-4 m-2">
                <textarea name="message" value={formData.message} onChange={handleChange} cols="50" rows="6" className="floating-input cursor-pointer shadow-md rounded-xl w-full transition-shadow duration-500 hover:shadow-xl focus:bg-sky-200" placeholder=" " required></textarea>
                <label className="floating-label">Message</label>
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="p-2 cursor-pointer shadow-md rounded-xl bg-blue-500 transition-colors duration-500 hover:bg-blue-600">Envoyer</button>
            </div>
          </div>
          {status && <p className="mt-4 text-center text-red-500">{status}</p>}
        </form>
        <br />
        <div className="contact-data w-full">
          <h2 className="mt-2">Coordonnées de contact</h2>
          <p>Pour toute question ou demande urgente, contactez-nous directement par email ou par téléphone :</p>
          <br />
            <ul>
              <li><strong>Email :</strong> <a href="mailto:contact@mycybersystems.com">contact@mycybersystems.com</a></li>
              <li><strong>Téléphone :</strong><address className="inline-block"><a href="tel:+32492443262">+32 492 44 32 62</a></address></li>
            </ul>
          <br />
          <p>Merci de votre visite et de votre intérêt pour notre entreprise. Nous avons hâte de vous entendre !</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
