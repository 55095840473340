import React from 'react';
import {  Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div className="">
      <Helmet>
        <meta property="og:title" content="CyberSystems" />
        <meta property="og:description" content="Découvrez CyberSystems, expert en informatique et en cybersécurité." />
        <meta property="og:image" content="https://mycybersystems.be/img/enterprise_logo.png" />
        <meta property="og:url" content="https://mycybersystems.be/" />
        <title>Home | CyberSystems</title>
        <meta name="description" content="Découvrez CyberSystems, votre expert en informatique et cybersécurité. Nous proposons des solutions avancées pour protéger vos données, optimiser vos systèmes et sécuriser votre entreprise contre les cybermenaces. Faites confiance à notre expertise pour une tranquillité d'esprit complète." />
        <meta name="keywords" content="cybersécurité, IT, informatique, dévellopement, Python, sécurité informatique, Namur, Gembloux, Sombreffe, Chaumont-Gistoux" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mycybersystems.be/" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="">
        <h1 className="text-2xl font-bold">Nos Services à Namur et dans le Brabant Wallon :</h1>
        <p>
          Chez <strong>CyberSystems</strong>, nous allions expertise technique et innovation pour vous offrir des solutions informatiques complètes, parfaitement adaptées à vos besoins spécifiques.
          <br />
          <br />
          <strong>Développement Web:</strong><br />
          Nous nous spécialisons dans le développement web à <strong>Namur, Gembloux, Chaumont-Gistoux, et Sombreffe.</strong><br />
          Nous créons des <strong>sites dynamiques</strong>, performants et conformes aux dernières tendances et technologies pour vous assurer une présence en ligne percutante.
          <br />
          <br />
          <strong>Gestion Informatique Professionnelle :</strong><br />
          Notre équipe excelle dans la <strong>gestion informatique</strong>, assurant le bon fonctionnement de vos infrastructures.<br />
          Avec une approche personnalisée, nous garantissons des solutions informatiques robustes et efficaces pour les entreprises du <strong>Namurois et de ses environs.</strong>
          <br />
          <br />
          <strong>Consultation en Cybersécurité :</strong><br />
          Nous vous aidons à <strong>protéger vos données</strong> et vos systèmes contre les menaces actuelles grâce à des stratégies de cybersécurité sur mesure.<br />
          Bénéficiez de notre expertise en <strong>cybersécurité</strong> pour sécuriser vos opérations et vos informations sensibles.
          <br />
          <br />
          <strong>Développement Python:</strong><br />
          Notre expertise en <strong>développement Python</strong> nous permet de concevoir des applications puissantes et efficaces.<br />
          Nous optimisons vos processus et favorisons l'innovation pour les entreprises situées dans le <strong>Brabant Wallon, Namur et les alentours.</strong>
          <br />
          <br />
          Ensemble, nous construisons des solutions robustes et sécurisées pour relever tous vos défis technologiques.<br />
          Contactez-nous pour découvrir comment <strong>CyberSystems</strong> peut transformer votre entreprise.
        </p>
      </div>

      <div className="service-button">
          <h2 className="mt-2 text-2xl font-bold">Services</h2>
          <h3 className="mb-3 text-xl">Nos services incluent: </h3>
        <div className="service-button-subdiv mr-3 flex flex-row gap-4 justify-between mb-6">
          <div className="flex flex-col items-center">
            <Link className="max-h-64 shadow-md rounded-xl p-5" to="/services#web-developpment">
              <strong className="mb-2">Développement Web</strong><br />
              <img width="80" height="80" alt=" web development icon" src="/assets/img/web_dev_icon.svg"/>
            </Link>
          </div>
          <div className="flex flex-col items-center">
            <Link className="max-h-64 shadow-md rounded-xl p-5" to="/services#it-management">
              <strong className="mb-3">Gestion Informatique</strong><br /> 
              <img width="80" height="80" alt="IT management icon" src="/assets/img/it_management.svg"/>
            </Link>
          </div>
          <div className="flex flex-col items-center">
            <Link className="max-h-64 shadow-md rounded-xl p-5" to="/services#cybersecurity">
              <strong className="mb-3">Consultation en Cybersécurité</strong><br />
              <img width="80" height="80" alt="Cybersecurity icon" src="/assets/img/cybersecurity_icon.svg"/>
            </Link>
          </div>
          <div className="flex flex-col items-center">
            <Link className="max-h-64 shadow-md rounded-xl p-5" to="/services#python-developpment">
              <strong className="mb-3">Développement de Logiciels en Python</strong><br />
              <img width="80" height="80" alt="Python development icon" src="/assets/img/python_dev_icon.svg"/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;