import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Services = () => {
	useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
        const element = document.getElementById(hash);
        if (element && element.tagName === 'DETAILS') {
            element.open = true;
            element.scrollIntoView();
        }
    }
	}, []);

	return (
		<div>
		    <Helmet>
		    	<meta property="og:title" content="CyberSystems" />
        		<meta property="og:description" content="Découvrez CyberSystems, votre expert en informatique et cybersécurité. Nous proposons des solutions avancées pour protéger vos données, optimiser vos systèmes et sécuriser votre entreprise contre les cybermenaces. Faites confiance à notre expertise pour une tranquillité d'esprit complète." />
        		<meta property="og:image" content="https://mycybersystems.be/img/enterprise_logo.png" />
        		<meta property="og:url" content="https://mycybersystems.be/" />
        		<link rel="canonical" href="https://mycybersystems.be/" />
  	  	    	<title>Services | CyberSystems</title>
  	  	    	<meta name="description" content="Découvrez CyberSystems, expert en informatique et en cybersécurité." />
        		<meta name="keywords" content="cybersécurité, IT, informatique, dévellopement, Python, sécurité informatique, Namur, Gembloux, Sombreffe, Chaumont-Gistoux" />
        		<meta name="robots" content="index, follow" />
        		<meta charset="UTF-8" />	
        		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		    </Helmet>
			<div>
				<div>
					<h1>Nos Services</h1>
					<p>Chez <strong>CyberSystems</strong>, nous nous engageons à offrir des <strong>services informatiques</strong> de qualité supérieure pour répondre à tous vos besoins. Nos domaines d'expertise incluent :</p>
				</div>
				<div>
					<details id="web-developpment" >
					<summary className="mb-1 w-2/3">Développement Web</summary>
					<p>Nous créons des <strong>sites web dynamiques et réactifs</strong>, utilisant les <strong>technologies web les plus récentes</strong> pour assurer une <strong>expérience utilisateur optimale.</strong><br />
					Que vous ayez besoin d'un <strong>site vitrine</strong>, d'une <strong>boutique en ligne</strong>, ou d'une <strong>application web personnalisée</strong>, notre équipe est là pour transformer vos idées en réalité.<br />
					Nous servons principalement les entreprises situées à <strong>Namur, Gembloux, Chaumont-Gistoux, et Sombreffe.</strong></p>
					</details>
				</div>
				<div>
					<details id="python-developpment">
					<summary className="mb-1 w-2/3">Développement Python</summary>
					<p>Python est au cœur de nombreuses <strong>solutions modernes</strong>, et notre expertise en <strong>développement Python</strong> nous permet de concevoir des <strong>applications robustes</strong> et performantes.<br />
					Qu'il s'agisse de <strong>scripts automatisés</strong>, d'outils de <strong>data analysis</strong>, ou de projets plus complexes, nous maîtrisons ce langage polyvalent pour répondre à vos besoins spécifiques à <strong>Namur</strong> et dans le <strong>Brabant Wallon</strong>.</p>
					</details>
				</div>
				<div>
					<details id="it-management">
					<summary className="mb-1 w-2/3">Gestion Informatique</summary>
					<p>La <strong>gestion efficace de votre infrastructure informatique</strong> est essentielle pour la continuité et la sécurité de vos opérations.<br />
					Nous offrons des services de <strong>gestion informatique</strong> complets, incluant <strong>l'administration de réseaux</strong>, la <strong>maintenance des systèmes</strong>, et le <strong>support technique</strong>.<br />
					Nous nous assurons que votre infrastructure reste stable et sécurisée pour les entreprises dans le <strong>Namurois et ses environs.</strong></p>
					</details>
				</div>
				<div>
					<details id="cybersecurity">
					<summary className="mb-1 w-2/3">Consultations en Cybersécurité</summary>
					<p>Dans un monde où les <strong>menaces informatiques</strong> sont en constante évolution, il est crucial de <strong>protéger vos données</strong> et vos systèmes.<br />
					Nos <strong>consultations en cybersécurité</strong> vous aident à identifier et à remédier aux <strong>vulnérabilités</strong>, à mettre en place des <strong>stratégies de protection efficaces</strong>,<br /> et à assurer la <strong>conformité avec les normes de sécurité</strong> tel que l'<a className="underline text-blue-800" href="https://www.iso.org/fr/standard/27001">ISO 27001</a>.
					Nous vous aidons à anticiper les risques et à renforcer votre posture de sécurité à <strong>Namur, Gembloux, et au-delà.</strong></p>
					</details>
				</div>
				<div>
					<h2>Pourquoi Choisir CyberSystems ?</h2>
					<p>Nous combinons <strong>passion, expertise, et innovation</strong> pour fournir des solutions personnalisées qui répondent aux exigences uniques de chaque client.<br />
					En choisissant <strong>CyberSystems</strong>, vous bénéficiez d'un partenariat avec des professionnels dévoués à la réussite de vos projets informatiques et à la protection de vos données.<br />
					Pour plus d'informations sur nos services ou pour discuter de vos besoins spécifiques, n'hésitez pas à <strong>nous contacter</strong>.<br />
					Nous sommes là pour vous aider à atteindre vos objectifs.<br /></p>
				</div>
			</div>
		</div>
		);
};

export default Services;